import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import auth from '@/auth/index'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/Home.vue'),
    meta: {
      title: 'مركز فعاليات وأنشطة خريطة - الصفحة الرئيسية',
      authRequired: true
    }
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/Login.vue'),
    meta: {
      title: 'مركز فعاليات وأنشطة خريطة - تسجيل الدخول'
    }
  },
  {
    path: '/index',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/pages/Home.vue'),
    meta: {
      title: 'مركز فعاليات وأنشطة خريطة - الصفحة الرئيسية',
      authRequired: true
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.authRequired)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!auth.isAuthenticated()) {
          next({
              path: '/auth/login',
              query: { redirect: to.fullPath }
          })
      } else {
        next()
      }
  } else {
      next() // make sure to always call next()!
  }
});

export default router

import jwt from "@/auth/index.js"
import router from '@/router'

export default {
    // JWT
    login({ commit }, payload) {

      return new Promise((resolve,reject) => {
        jwt.login(payload)
          .then(response => {
            // If there's user data in response
            if(response.data.access_token && response.data.identity) {
  
              // Set accessToken
              localStorage.setItem("accessToken", response.data.access_token)

              // Update user details
              commit('USER_LOGGED_IN', response.data.identity)

              // Set bearer token in axios
              commit("saveToken", response.data.access_token)

              localStorage.setItem("loggedIn", 'true');

              // Navigate User to homepage
              router.push(router.currentRoute.query.redirect || '/index')

              resolve(response)
            } else {
              reject( Object.values(response.data.errors).map(item => item[0]) )
            }
          })
          .catch(errors => {
              reject({message: errors })
          })
      })
    },
    logout({ commit }) {
        commit("logout")
    }
}

import Vue from 'vue'
import App from './App.vue'


import Antd from 'ant-design-vue';

import 'ant-design-vue/dist/antd.css'
import './assets/css/antd-rtl.css'
Vue.use(Antd);


// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


// Cropping images
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'
Vue.use(VueCroppie);


// axios
import axios from "./axios.js"
Vue.prototype.$http = axios


// Vue Router
import router from './router'


// Vuex Store
import store from './store/index'


import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDwSWXHMlihdzzM66seauHSfSzlqRwdzfs',
    v: '3.26',
    libraries: 'places' // This is required if you use the Autocomplete plugin
  }
});

Vue.config.productionTip = false

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  created(){
    let html = document.querySelector("html")
     html.setAttribute('dir','rtl')
     html.setAttribute('lang','ar')
  }
})
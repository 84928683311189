import axios from "@/axios"

export default {
  fetchEvents({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/events/mines", {
        params,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
        .then((response) => {
          commit('SET_EVENTS', response.data.items)
          resolve(response)
        })
        .catch((error) => { reject(error.response.data.message) })
    })
  },
  fetchEvent(context, eventId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/events/show?id=${eventId}` , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  addEvent({ commit }, event) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/events/new", event, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
        .then((response) => {
          commit('ADD_EVENT', Object.assign(event, {id: response.data.event_id}))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  editEvent({ commit }, event) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/events/edit?id=${event.id}`, event.data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
        .then((response) => {
          commit('EDIT_EVENT', Object.assign(event.data, { id: event.id }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}

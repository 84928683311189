import axios from "@/axios"

export default {
  saveToken(state, accessToken) {
    state.access_token = accessToken
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
  },
  USER_LOGGED_IN(state, user) {
    state.logged_in = true
    state.user = user
  },
  logout(state) {
    localStorage.removeItem("accessToken")
    localStorage.removeItem('loggedIn');
    state.logged_in = false
    state.user = {}
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import auth from './auth/index.js'
import events from './events/index.js'


export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth: auth,
        events: events,
    },
    plugins: [createPersistedState()],
    strict: process.env.NODE_ENV !== 'production'
})

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  watch: {
    '$route' (to) {
    document.title = to.meta.title || 'مركز فعاليات وأنشطة خريطة'
    }
  }
};
</script>

<style scoped lang="scss">
#app {
  height: 100%;
}
</style>
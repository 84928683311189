export default {
  SET_EVENTS(state, events) {
    state.events = events
  },
  ADD_EVENT(state, event) {
    state.events.push(event)
  },
  EDIT_EVENT(state, event) {
    const eventIndex = state.events.findIndex((e) => e.id == event.id)
    Object.assign(state.events[eventIndex], event)
  },
}

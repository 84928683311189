import axios from "../axios.js"
import store from "../store/index.js"

export default {
  init() {
    axios.interceptors.request.use(function (config) {
        const token = store.state.auth.access_token;
        config.headers.authorization = 'Bearer ' + token;
        return config;
    })

    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      return Promise.reject(error)
    })
  },
  login(payload) {
    return axios.post("/api/v1/user/sign-in", payload)
  },
  refreshToken() {
    return axios.post("/api/v1/auth/refresh-token", {token: localStorage.getItem("accessToKen")})
  },
  logout() {
    store.dispatch("auth/logout")
  },

  isAuthenticated() {
    return (
        localStorage.getItem("accessToken") != null &&
        localStorage.getItem('loggedIn') === 'true'
    );
  }
}

// axios
import axios from 'axios'

// const domain = 
export default axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // You can add your headers here
  headers : {
    'accept-language' : 'en'
  }
})
